//-----------------KZAstanaPro----------------------------------------------------
@font-face {
  font-family: 'KZAstanaPro';
  src: url('/assets/fonts/KZAstanaPro-Regular/KZ_Astana_Pro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'KZAstanaPro';
  src: url('/assets/fonts/KZAstanaPro-Bold/KZ_Astana_Pro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

//---------------Nunito Sans--------------------------------------------------------
@font-face {
  font-family: 'Nunito Sans';
  src: url('/assets/fonts/Nunito Sans/NunitoSans10pt-Regular.woff2') format('woff2'),
  url('/assets/fonts/Nunito Sans/NunitoSans10pt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
