.logo-div {
  display: block;
  margin-bottom: 10px;
}
.home-link {
  display: block;
  height: 39px;
  background-image: url(../../../../../libs/profilum-assets/src/lib/images/icons/Main-VIolet.svg);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.home-link.adm {
  margin-bottom: 20px;
  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
}

// --------------gender toggle-----------------
.gender-choice {
  display: inline-block;
}

.gender-choice-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.gender-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  height: 30px;
  padding-left: 3px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px 1px 2px 2px;
  border-color: rgba(53, 53, 53, 0.1);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  color: #353535;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
}

.gender-button.right {
  padding-right: 3px;
  padding-left: 0px;
  border-right-style: solid;
  border-right-width: 2px;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 0px 100px 100px 0px;
}

.gender-button.active {
  border-color: $blue_common;
  background-color: $blue_common;
  color: #fff;
}

.profession-icon {
  position: relative;
  margin-bottom: 10px;
  text-align: center;
}

.profession-icon.parent-tab-icon-div {
  height: 100%;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.profession-icon.parent-tab-icon-div.dd-add-child {
  margin-right: 20px;
}

.profession-icon.hor {
  margin-right: 20px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.no-profession-icon {
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  border-radius: 100px;
  @media (max-width: 450px) {
    width: 60px;
    height: 60px;
  }
}

.angle {
  position: absolute;
  right: -20px;
  bottom: 0px;
}

.angle._2 {
  left: -20px;
  right: auto;
  bottom: 0px;
}

.angle.hide {
  display: none;
}

.subjects {
  margin-top: 5px;
}

.subject {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 4px 10px 5px;
  border-radius: 100px;
  background-color: #9375fb;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
}

//-------notice----------

.notice {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.notice-column {
  padding-top: 3px;
  text-align: right;
}

.notice-dropdown {
  margin-right: 50px;
}

.notice-dropdown._2 {
  margin-right: 30px;
}

.notice-dropdown-toggle {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.notice-dropdown-toggle.bookmark {
  background-image: url('/assets/images/dashboard/bookmark.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.notice-dropdown-toggle.bookmark.active {
  background-image: url('/assets/images/dashboard/bookmark-active.svg');
}

.notice-dropdown-toggle.notification {
  background-image: url('/assets/images/dashboard/notification.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.notice-dropdown-toggle.notification.active {
  background-image: url('/assets/images/dashboard/notification-active.svg');
}

.notice-num {
  position: absolute;
  top: 2px;
  right: 3px;
  height: 17px;
  min-width: 17px;
  padding-top: 2px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 50px;
  background-color: #fd651a;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 10px;
  line-height: 13px;
}

.notice-num.yellow {
  background-color: #ffe572;
  color: #353535;
  font-weight: 700;
}

.notice-list {
  background-color: transparent;
}

.notice-list.w--open {
  width: 400px;
  padding-top: 20px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.notice-list.teacher-recom {
  left: 50%;
  right: 0px;
}

.notice-title {
  padding-top: 15px;
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(53, 53, 53, 0.05);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  color: rgba(53, 53, 53, 0.5);
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.notice-title.recom {
  margin-right: 30px;
  margin-left: 30px;
  padding-top: 20px;
  padding-bottom: 5px;
  border-bottom-style: none;
  font-size: 20px;
  line-height: 25px;
}

.notice-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 50px 14px 30px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid rgba(53, 53, 53, 0.05);
  text-align: left;
}

.notice-block.new {
  position: relative;
  border-top-style: none;
}

.notice-image {
  overflow: hidden;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
}

.user-name-notice {
  font-weight: 700;
}

.notice-text {
  margin-bottom: 3px;
  color: #353535;
}

.notice-date {
  color: rgba(53, 53, 53, 0.5);
}

.new-notice-point {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #fc6419;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.old-notices {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(53, 53, 53, 0.05);
}

.notices-block {
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.04);
}

.notices-block.recom {
  background-color: #fff;
}

.notice-gradient {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  height: 40px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f7f7f7), to(hsla(0, 0%, 100%, 0)));
  background-image: linear-gradient(0deg, #f7f7f7, hsla(0, 0%, 100%, 0));
}

.notice-gradient.white {
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fff), to(hsla(0, 0%, 100%, 0)));
  background-image: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
}

.notice-gradient.white.hide {
  display: none;
}

.notice-list-container {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.notices-scroll {
  position: relative;
  overflow: auto;
  max-height: 460px;
}

.new-notices {
  background-color: #fff;
}

.notices-div-block {
  position: relative;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(53, 53, 53, 0.05);
}

.triangle {
  position: absolute;
  left: 206px;
  top: -11px;
}

.triangle.teacher-recom {
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}
.icon-hover {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}
.testing-progress-bar {
  width: 10%;
  height: 5px;
  border-radius: 10px;
  background-color: #0B93F5;
}

.testing-progress-bar.full {
  width: 100%;
}

.testing-logo-link {
  margin: 10px 15px 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  .new-logo {
    width: 39px;
    height: auto;
  }
  img {
    height: 39px;
    width: auto;
  }
}

.student-prof-icon {
  border-radius: 100px;
}
.checkbox-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-icon-price {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #e6e9ed;
}

.checkbox-icon-price:hover {
  background-color: #cfd4da;
}

.checkbox-icon-price.checked {
  background-color: #9375fb;
  background-image: url('/assets/images/icons/checked_blank.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.event-page-heart {
  width: 22px;
  height: 20px;
  background-image: url('/assets/images/icons/heart-white.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.attach-icon {
  margin-right: 10px;
}
.attach-icon-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: rgba(147, 117, 251, 0.1);
}

.attach-icon-div.border {
  border: 2px solid #7852fb;
}

.attach-icon-div.border.white {
  border-color: #fff;
  background-color: hsla(0, 0%, 100%, 0.15);
}

.attach-icon-div.border.event-img {
  margin-right: 0px;
}

.delete-attachment {
  opacity: 0.2;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}

.delete-attachment:hover {
  opacity: 1;
}

.high-school-icon {
  margin-bottom: 2px;
  margin-left: 2px;
}

.event-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 320px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
  color: #9375fb;
}

.event-image.uploaded-photo {
  background-image: url('/assets/images/dashboard/Bitmap_1.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.add-event-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
}

.add-event-image.white {
  color: #fff;
}

.add-event-image.hor {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  text-align: left;
}

.event-image-text {
  margin-bottom: 45px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
  line-height: 15px;
}

.edit-icon-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.delete-event {
  width: 14px;
  height: 18px;
  margin-left: 30px;
  background-image: url('/assets/images/icons/delete-icon.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.delete-event:hover {
  background-image: url('/assets/images/icons/delete-icon-hover.svg');
}

.cost-symbol {
  margin-left: 10px;
  font-size: 15px;
}

.login-logo {
  margin-bottom: 70px;
}

.login-logo-img {
  display: block;
  width: 200px;
  height: auto;
  @media screen and (max-width: 835px) {
    width: 150px;
  }
  @media screen and (max-width: 425px) {
    width: 130px;
  }
}

.checkbox-div {
  width: 25px;
  height: 25px;
  margin-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: rgba(53, 53, 53, 0.1);
}

.checkbox-div.checked {
  background-color: #9375fb;
  background-image: url('/assets/images/icons/checked_blank.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.checkbox-div.checked.no-active {
  background-color: rgba(53, 53, 53, 0.1);
  background-image: url('/assets/images/icons/checked-no-active.svg');
}

.angle-parent-tabs {
  position: absolute;
  right: -20px;
  bottom: 0px;
  display: none;
}

.angle-parent-tabs._2 {
  left: -20px;
  right: auto;
  bottom: 0px;
}

.angle-parent-tabs.active {
  display: inline-block;
}

.logo_cornered {
  width: 40px;
  height: auto;
  position: absolute;
  top: 24px;
  left: 32px;
}

.info-popup.recommendation {
  width: 250px;
  font-size: 13px;
}

ol.info-exacting {
  margin-left: 0;
  padding-left: 0;

  li {
    list-style-type: none;
  }
}

.info-exacting {
  .info-pswrd-icon {
    line-height: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background: url(/assets/images/icons/checkIcon-green.svg) no-repeat left;
  }

  .info-pswrd-icon.not-correct {
    background: url(/assets/images/icons/checkIcon-gray.svg) no-repeat left;
  }
}

.input-clear {
  position: absolute;
  right: 8px;
  top: calc(50% - 10px);
  img {
    max-width: 9px;
    height: auto;
  }

  svg {
    path {
      stroke: #ff5722;
    }
  }
}

.eye {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: calc(50% - 15px);
  z-index: 100;
  width: 40px;
  height: 30px;
  background: url(/assets/images/icons/open.png) center no-repeat;
  cursor: pointer;
}
.eye__closed {
  background: url(/assets/images/icons/close.png) center no-repeat;
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 6px;
  width: 40px;
  height: 30px;
  cursor: pointer;
}
//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .logo-div {
    margin-bottom: 10px;
  }
  .event-image {
    height: 35vw;
  }
}
@media screen and (max-width: 768px) {
  .logo-div {
    max-width: 70px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .subject {
    font-size: 12px;
    line-height: 14px;
  }

  .notice {
    padding-right: 0%;
  }
  .notice-dropdown {
    position: relative;
    margin-right: 0px;
  }
  .notice-dropdown._2 {
    margin-right: 10px;
  }
  .notice-list.w--open {
    top: 100%;
    right: 0px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  .notice-block {
    font-size: 12px;
    line-height: 18px;
  }
  .triangle {
    left: auto;
    top: -11px;
    right: 5px;
  }
  .parent-tab-icon {
    height: 30px;
  }
  .event-image {
    height: 45vw;
  }
  .login-logo {
    margin-bottom: 50px;
  }
  .logo_cornered {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .profession-icon.parent-tab-icon-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .notice-list.w--open {
    width: 400px;
    max-width: 700%;
  }
  .notice-block {
    padding-right: 40px;
    padding-left: 20px;
  }
  .delete-event {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .h3-add-goal {
    font-size: 23px;
  }

  .gender-choice {
    margin-left: 15px;
  }
}
