.testing-buttons-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 12px 0;
}

.tab-pane {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 7% 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.tab-pane.parent-profile {
  display: block;
  padding-top: 48px;
  padding-right: 50px;
  padding-left: 50px;
}

.tab-pane.adm {
  display: block;
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
}

.tab-pane.left {
  display: block;
}

.tab-pane-professions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #353535;
  opacity: 0.3;
}

.tabs-content {
  margin-top: -30px;
  margin-right: -30px;
  margin-bottom: -30px;
  margin-left: -30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}
.page-tab {
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 5%);
  margin-top: 32px;
}

.profile-data-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 22px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  &.inline-row {
    flex-direction: row;
  }
}

.no-test-block {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.content-row {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100px;
  height: 100%;
  padding-top: 13px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: rgba(53, 53, 53, 0.02);
  text-align: center;
}

.class-header {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
  font-size: 15px;
  font-weight: 400;
}

.class-header._2 {
  font-weight: 500;
}
.class-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.class-row.class-header-row-3 {
  padding-right: 25px;
}
.class-row-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 22px;
  padding-bottom: 22px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.class-row-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.class-header-number {
  color: rgba(53, 53, 53, 0.2);
  font-size: 13px;
  font-weight: 400;
}

.class-header-number._2 {
  color: #353535;
}

.content-column.main-column {
  max-width: 70%;
  padding-right: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-column._2 {
  max-width: 300px;
  min-width: 30%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-column._2.class-column-2 {
  margin-left: 50px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.content-column._2.hide {
  display: none;
}

.tabs-menu {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: -30px -30px -10px;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tab-link {
  position: relative;
  min-width: 140px;
  padding: 20px 30px 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: transparent;
  box-shadow: inset 0 -10px 0 0 #fff;
  color: #353535;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.tab-link:hover {
  background-color: rgba(53, 53, 53, 0.02);
}

.tab-link.w--current {
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-weight: 700;
}

.tab-link.add-tab {
  color: rgba(53, 53, 53, 0.2);
}

.tab-link.add-tab:hover {
  color: #5d7aff;
}

.tab-link.parent-tabs {
  padding-right: 50px;
  padding-bottom: 30px;
  padding-left: 50px;
  text-align: left;
}

.tab-link.current {
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-weight: 700;
}

.tab-link.adm {
  padding-top: 30px;
  padding-bottom: 40px;
}

.tab-link.login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
  padding-bottom: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 24px;
  line-height: 30px;
}

.tabs {
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
}

.tabs.login {
  text-align: left;
}
.tab-container {
  width: 600px;
  max-width: 100%;
}

.scheme {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 600px;
  min-width: 460px;
  margin-top: 10px;
  margin-bottom: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.scheme-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -20px;
}

.scheme-column {
  padding-right: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.scheme-text-link {
  display: inline-block;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.tasks-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.tasks-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.task-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  min-height: 290px;
  padding-top: 50px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
  background-color: #ffe572;
  text-align: center;
}

.task-block.done {
  background-color: rgba(147, 117, 251, 0.05);
  box-shadow: none;
}

.task-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.task-icon-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  margin-bottom: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: #fef18a;
  box-shadow: 0 10px 30px 0 rgba(224, 153, 0, 0.3);
}

.task-icon-div.done {
  background-color: #9375fb;
  box-shadow: 0 10px 30px 0 rgba(147, 117, 251, 0.5);
}

.task-text {
  color: #353535;
  font-size: 16px;
}

.task-text.width-180 {
  max-width: 180px;
}

.task-date-text {
  margin-top: 20px;
  color: #9375fb;
  line-height: 18px;
  letter-spacing: -0.5px;
}
.quote-block {
  position: relative;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  margin-top: 100px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}
.quote-photo {
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin-top: 3px;
  margin-left: 40px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
}

.parameters {
  margin-bottom: 50px;
  padding-top: 15px;
  color: rgba(53, 53, 53, 0.5);
  text-align: left;
}

.parameter-block {
  padding-top: 30px;
  line-height: 16px;
}

.parameter-line {
  position: relative;
  height: 5px;
  margin-top: 3px;
  margin-right: 30px;
  border-radius: 5px;
  background-color: rgba(53, 53, 53, 0.05);
}

.parameter-line-color {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  border-radius: 5px;
  background-color: #13e5db;
}

.parameter-line-color.purple {
  width: 68%;
  background-color: #9375fb;
}

.parameter-line-color.blue {
  width: 67%;
  background-color: #36acff;
}

.parameter-line-color.orange {
  width: 54%;
  background-color: #fd651a;
}

.parameter-line-color.pink {
  width: 42%;
  background-color: #f743de;
}

.parameter-line-color.yellow {
  width: 25%;
  background-color: #fdcf00;
}

.parameter-line-color.cian {
  width: 96%;
}

.parameter-number {
  position: absolute;
  top: 50%;
  right: -30px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  color: #353535;
  font-size: 12px;
  line-height: 15px;
}

.change-name-text {
  padding-bottom: 35px;
  color: rgba(53, 53, 53, 0.5);
}

.change-name-div {
  overflow: hidden;
}
.welcome-section {
  padding: 65px 80px 110px 100px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.welcome-section.testing {
  padding-top: 90px;
}

.welcome-section.teacher-welc {
  padding-top: 55px;
  padding-right: 47px;
  padding-bottom: 100px;
}

.welcome-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.welcome-row.testing-block {
  margin-right: -50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.welcome-row.teacher-welc {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.welcome-column {
  width: 375px;
  margin-right: 10px;
}

.welcome-column.testing {
  width: 380px;
  padding-right: 0px;
}

.welcome-column.teacher-welc {
  margin-top: 50px;
}

.welcome-h2 {
  margin-bottom: 30px;
  font-size: 35px;
  line-height: 40px;
  text-align: left;
}

.welcome-text {
  margin-bottom: 35px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.welcome-text.teacher-welc {
  max-width: 340px;
  color: #353535;
  font-size: 15px;
}

.welcome-image {
  margin-top: 50px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.progress-block {
  margin-top: -10px;
  margin-bottom: 15px;
}

.progress-block.parent-test {
  margin-top: 0px;
}

.progress-text {
  margin-bottom: 10px;
  color: #9375fb;
  font-size: 16px;
  font-weight: 700;
}

.progress-bar {
  position: relative;
  height: 5px;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.05);
}

.progress-bar-color {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 16%;
  border-radius: 10px;
  background-color: #9375fb;
}

.h3-invite-parent {
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 45px;
}

.invite-parent-text {
  margin-bottom: 60px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.invite-parent-label {
  font-size: 16px;
  font-weight: 400;
}

.invite-parent-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.invite-parent-row._2 {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.invite-parent-lb.lb {
  display: none;
}

.tab-link-parent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tab-link-parent.dd-add-child {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-photo-child {
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
}

.tabs-child-school {
  margin-top: 3px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
}

.test-parent-div {
  padding: 30px 50px 34px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(147, 117, 251, 0.2);
  border-radius: 10px;
}

.test-parent-div.testing {
  margin-bottom: 130px;
}

.test-parent-div.recommendations {
  margin-bottom: 45px;
}

.test-parent-h3 {
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
}
.test-parent-text {
  margin-bottom: 35px;
  color: #353535;
}

.test-parent-column {
  width: 350px;
  margin-right: 10px;
  margin-bottom: 25px;
  padding-top: 16px;
}

.test-parent-column.testing {
  width: 400px;
  padding-right: 0px;
}

.favorites-heading {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: rgba(53, 53, 53, 0.5);
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}

.favorites-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.favorites-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.favorites-block {
  width: 220px;
  height: 280px;
  border-radius: 10px;
  background-color: #01d099;
}

.favorites-block.gray {
  background-color: rgba(108, 120, 144, 0.5);
}

.favorites-block.yellow {
  background-color: #ffe572;
}

.school-edit-link {
  display: inline-block;
  margin-top: 3px;
  margin-left: 10px;
  padding-left: 20px;
  background-image: url('/assets/images/icons/edit-icon_1.svg');
  background-position: 0px 50%;
  background-repeat: no-repeat;
}

.school-edit-link:hover {
  background-image: url('/assets/images/icons/edit-icon-hover.svg');
}

.professions-section {
  padding: 45px 60px 110px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.professions-section.testing {
  padding-top: 90px;
}

.professions-section.teacher-welc {
  padding-top: 55px;
  padding-right: 47px;
  padding-bottom: 100px;
}

.professions-h2 {
  margin-bottom: 25px;
  font-size: 35px;
  line-height: 40px;
  text-align: left;
}

.professions-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -15px;
  margin-right: -10px;
  margin-left: -10px;
  padding-bottom: 25px;
}

.professions-row._2 {
  margin-bottom: -30px;
}

.professions-row.recom {
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.professions-column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33333333%;
  padding: 15px 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}
.profession-line {
  height: 2px;
  margin-top: 10px;
  margin-bottom: 45px;
  background-color: rgba(53, 53, 53, 0.05);
}

.professions-filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.catalog-professions-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.similar-prof-block {
  margin-top: 30px;
  padding: 45px 50px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.similar-prof-heading {
  margin-bottom: 35px;
  color: #353535;
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
}
.events-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -15px;
  margin-right: -10px;
  margin-left: -10px;
  padding-bottom: 15px;
}

.events-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33333333%;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.catalog-events-row {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.event-page-section {
  position: relative;
  margin-top: -15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.event-page-section.parent-profile {
  display: block;
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 50px;
}

.event-page-section.create {
  padding: 45px 50px 50px;
}
.event-page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 250px;
  padding: 50px 50px 45px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #5d7aff;
  color: #fff;
}

.event-header-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.event-header-column._2 {
  padding-top: 40px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.event-page-age {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 15px;
}

.event-page-h1 {
  max-width: 600px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 400;
}

.event-page-h1-div {
  padding-right: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-page-header-link {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
}

.event-page-header-link:hover {
  color: #fff;
  text-decoration: underline;
}

.event-page-content {
  padding: 50px;
}

.event-page-description-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.event-page-description-column {
  width: 68%;
  margin-bottom: 55px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.event-page-description-column._2 {
  width: 32%;
  padding-left: 55px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.event-page-h2 {
  margin-bottom: 25px;
  font-size: 25px;
  text-align: left;
}

.event-page-descr-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -40px;
}

.event-page-descr-column {
  max-width: 33.33333333%;
  padding-right: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.event-page-h4 {
  margin-bottom: 10px;
  color: #353535;
  font-size: 15px;
}

.event-description-list {
  margin-bottom: 30px;
  padding-left: 18px;
  color: #9375fb;
}

.event-description-title {
  margin-bottom: 10px;
  color: #353535;
}

.event-page-description-text {
  margin-bottom: 0px;
  color: #353535;
  font-size: 15px;
}

.event-page-text {
  margin-bottom: 5px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
  line-height: 15px;
}

.event-page-h3 {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 25px;
}

.event-page-date {
  min-height: 21px;
  margin-bottom: 20px;
  padding-top: 3px;
  padding-left: 30px;
  background-image: url('/assets/images/dashboard/calendar-icon.svg');
  background-position: 0px 50%;
  background-repeat: no-repeat;
  color: #979797;
  font-size: 13px;
  line-height: 15px;
}

.event-page-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 20px;
  margin-bottom: 20px;
  padding-left: 30px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('/assets/images/icons/price.svg');
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.event-page-price-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
}

.event-final-price {
  margin-right: 10px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.25px;
}

.event-page-address {
  padding-left: 30px;
  background-image: url('/assets/images/icons/address-icon.svg');
  background-position: 0px 0px;
  background-repeat: no-repeat;
}
.similar-events-line {
  height: 2px;
  margin-top: 10px;
  margin-bottom: 45px;
  background-color: rgba(53, 53, 53, 0.05);
}

.event-gradient-bg-2 {
  position: relative;
  display: block;
  overflow: hidden;
  height: 150px;
  border-radius: 10px;
  background-image: linear-gradient(45deg, #6679d1, #1e3bd1);
  color: #fff;
  text-align: left;
}
.event-gradient-bg-2:hover {
  color: #fff;
}
.event-gradient-bg-2.purple {
  background-image: linear-gradient(45deg, #8b74da, #4d26d6);
}
.event-gradient-bg-2.green {
  background-image: linear-gradient(45deg, #2cb855, #4fd255);
}
.event-gradient-bg-2.yellow {
  background-image: linear-gradient(45deg, #c8861b, #fdcf00);
}
.event-gradient-bg-2.orange {
  background-image: linear-gradient(45deg, #e03600, #e27f5f);
}
.event-gradient-bg-2.blue {
  background-image: linear-gradient(45deg, #53a0fd, #13e5db);
}
.event-gradient-bg-2.all-events {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(147, 117, 251, 0.1);
  background-image: none;
  color: #9375fb;
  font-size: 15px;
  text-align: center;
}
.tasks-tabs {
  margin-top: 30px;
}

.tasks-tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tasks-tab-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 2px;
  padding: 12px 15px 9px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 3px solid transparent;
  background-color: rgba(53, 53, 53, 0.03);
  color: #353535;
  font-size: 13px;
  line-height: 15px;
}

.tasks-tab-link:hover {
  color: #5d7aff;
}

.tasks-tab-link.w--current {
  color: #9375fb;
  font-weight: 700;
}

.tasks-tab-link.locked {
  color: rgba(53, 53, 53, 0.2);
  cursor: default;
}

.tasks-tab-link.current {
  border-bottom-color: #9375fb;
  color: #9375fb;
  font-weight: 700;
}

.task-tab-num {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
  background-color: rgba(53, 53, 53, 0.1);
  color: rgba(53, 53, 53, 0.5);
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
}

.task-tab-num.current {
  background-color: #9375fb;
  color: #fff;
}

.task-tab-pane {
  padding-top: 30px;
}

.task-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.task-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  min-width: 295px;
  padding-right: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.task-card {
  padding: 35px 35px 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;
  text-align: center;
}

.task-card-heading {
  min-height: 40px;
  color: #353535;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.2px;
}

.task-slider {
  height: auto;
  background-color: transparent;
}

.task-slide-arrow {
  bottom: -20px;
  width: 20px;
  height: 35px;
  margin-right: -30px;
  margin-left: -30px;
  opacity: 0.2;
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}

.task-slide-arrow:hover {
  opacity: 1;
}

.task-slide-nav {
  position: relative;
  height: auto;
  margin-top: 10px;
  margin-bottom: -10px;
  padding-top: 10px;
  font-size: 8px;
}

.tasks-tab-link-text {
  max-width: 118px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.tasks-tabs-menu-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
}

.attached-file-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #9375fb;
}

.attached-file-row._2 {
  margin-bottom: 20px;
}

.courses-row-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.courses-number {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-status-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 60px;
  padding: 12px 70px 8px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(53, 53, 53, 0.06);
  color: rgba(53, 53, 53, 0.4);
  font-size: 13px;
  line-height: 15px;
}

.event-status-text {
  margin-bottom: 5px;
}

.event-status-text.arch {
  min-height: 18px;
  padding-top: 2px;
  padding-left: 30px;
  background-image: url('/assets/images/icons/archive-black-box.svg');
  background-position: 0px 0px;
  background-repeat: no-repeat;
  color: rgba(53, 53, 53, 0.4);
  font-size: 13px;
  line-height: 15px;
}

.event-status-text.arch.short {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.event-card-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-status-block-2 {
  margin-bottom: -5px;
}

.event-status-text-2 {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
  color: #979797;
  font-size: 13px;
  line-height: 15px;
}

.admin-header-event-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 50px 45px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
  color: #979797;
  font-size: 13px;
  line-height: 15px;
}

.admin-header-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.edit-event-col {
  padding-left: 30px;
}

.edit-event-col.arch {
  margin-bottom: -5px;
  padding-left: 45px;
}

.edit-event-col-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

//================================================
//==================MEDIA=========================
//================================================
//============================991px========================
@media screen and (max-width: 991px) {
  .tab-pane.parent-profile {
    padding-right: 30px;
    padding-left: 30px;
  }
  .tab-pane.adm {
    padding-right: 30px;
    padding-left: 30px;
  }
  .welcome-section {
    padding-right: 70px;
    padding-bottom: 80px;
    padding-left: 70px;
  }
  .welcome-section.testing {
    padding-top: 65px;
  }
  .welcome-section.teacher-welc {
    padding-bottom: 80px;
  }
  .welcome-row {
    display: block;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .welcome-row.testing-block {
    margin-right: 0px;
  }
  .welcome-column {
    width: auto;
    margin-right: 0px;
    padding-right: 0px;
  }
  .welcome-column.testing {
    width: auto;
    padding-right: 0px;
  }
  .welcome-column.teacher-welc {
    margin-top: 0px;
  }
  .header-testing {
    position: static;
  }
  .content-row {
    position: relative;
    display: block;
  }
  .class-row-2 {
    padding-left: 30px;
  }
  .content-column.main-column {
    max-width: 100%;
    margin-bottom: 80px;
    padding-right: 0%;
  }
  .content-column.main-column.without-right-column {
    margin-bottom: 0px;
  }
  .content-column._2 {
    width: auto;
    max-width: 100%;
    min-width: 0%;
  }
  .tab-link.parent-tabs {
    padding-right: 30px;
    padding-left: 30px;
  }
  .task-column {
    min-width: 0px;
  }
  .invite-parent-row {
    position: relative;
  }
  .invite-parent-row._2 {
    display: block;
  }

  .test-parent-div.testing {
    margin-bottom: 0px;
  }
  .test-parent-column {
    width: auto;
    margin-right: 0px;
    padding-top: 0px;
    padding-right: 0px;
  }
  .test-parent-column.testing {
    width: auto;
    padding-right: 0px;
  }
  .class-header._2 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    font-size: 14px;
    line-height: 16px;
  }
  .professions-section {
    padding-right: 40px;
    padding-bottom: 80px;
    padding-left: 40px;
  }
  .professions-section.testing {
    padding-top: 65px;
  }
  .professions-section.teacher-welc {
    padding-bottom: 80px;
  }

  .professions-row {
    display: block;
  }
  .professions-row._2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .professions-column-2 {
    display: block;
    width: 100%;
  }
  .professions-column-2._2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 33.33333333%;
  }
  .professions-filters {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .catalog-professions-row {
    display: block;
  }
  .similar-prof-block {
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
  }
  .events-row {
    display: block;
  }
  .events-column {
    display: block;
    width: 100%;
  }
  .catalog-events-row {
    display: block;
  }
  .event-page-section {
    margin-top: 0px;
    padding-bottom: 70px;
  }
  .event-page-section.parent-profile {
    padding-right: 30px;
    padding-left: 30px;
  }
  .event-page-section.create {
    padding-right: 40px;
    padding-left: 40px;
  }
  .event-page-header {
    padding-right: 40px;
    padding-left: 40px;
  }
  .event-header-column._2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .event-page-h1 {
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 36px;
  }
  .event-page-h1-div {
    padding-right: 0px;
  }
  .event-page-content {
    padding-right: 0px;
    padding-left: 0px;
  }
  .event-page-description-row {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .event-page-description-column {
    width: 100%;
  }
  .event-page-description-column._2 {
    width: auto;
    margin-left: 0px;
    padding-left: 0px;
  }
  .event-page-descr-row {
    margin-right: -40px;
  }
  .event-page-descr-column {
    padding-right: 40px;
  }
  .event-page-address {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .event-page-map-block {
    padding-right: 40px;
    padding-left: 40px;
  }
  .task-card {
    padding-right: 20px;
    padding-left: 20px;
  }
  .task-slide-arrow {
    bottom: -3px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .event-status-block {
    padding-right: 20px;
  }
  .event-status-text.ev-page {
    display: inline-block;
    margin-right: 20px;
  }
  .event-card-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .admin-header-event-page {
    display: block;
    padding: 25px 40px 35px;
  }
  .edit-event-col-2 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
//============================768px========================
@media screen and (max-width: 768px) {
  .testing-buttons-div {
    margin: 50px 0 12px;
  }

  .tab-pane {
    padding-right: 25px;
    padding-left: 25px;
  }
  .tab-pane.parent-profile {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tab-pane.adm {
    padding-right: 20px;
    padding-left: 20px;
  }

  .heading {
    font-size: 16px;
    line-height: 18px;
  }

  .profile-data-row:not(.inline-row) {
    display: block;
  }

  .welcome-section {
    padding-right: 60px;
    padding-left: 60px;
  }

  .welcome-h2 {
    font-size: 32px;
    line-height: 36px;
  }

  .header-testing {
    max-width: 50%;
  }

  .navbar {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: auto;
    display: none;
    width: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .class-row-2 {
    padding-left: 20px;
  }
  .tab-link {
    min-width: 100px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .tab-link.parent-tabs {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tab-link.adm {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .tab-link.login {
    font-size: 20px;
    line-height: 26px;
  }
  .scheme {
    max-width: 100%;
    min-width: 0px;
    text-align: center;
  }
  .scheme-row {
    display: block;
    margin-right: 0px;
  }
  .scheme-column {
    margin-bottom: 20px;
    padding-right: 0px;
  }
  .task-div {
    padding-right: 20px;
    padding-left: 20px;
  }

  .task-text {
    font-size: 14px;
    line-height: 18px;
  }
  .h3-invite-parent {
    font-size: 32px;
    line-height: 40px;
  }
  .invite-parent-text {
    margin-bottom: 40px;
  }
  .invite-parent-row {
    display: block;
  }
  .tab-photo-child {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .tabs-child-school {
    margin-top: 0px;
    font-size: 10px;
  }
  .test-parent-div {
    padding-top: 35px;
    padding-left: 30px;
  }
  .test-parent-h3 {
    font-size: 32px;
    line-height: 36px;
  }
  .favorites-column {
    width: 50%;
    min-width: 240px;
  }
  .favorites-block {
    width: 100%;
  }
  .class-header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .class-header._2 {
    padding-left: 20px;
  }
  .professions-section {
    padding-right: 20px;
    padding-left: 20px;
  }

  .professions-h2 {
    font-size: 30px;
    line-height: 34px;
  }

  .professions-row._2 {
    display: block;
  }
  .professions-column-2 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .professions-column-2._2 {
    width: 100%;
  }
  .similar-prof-block {
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .similar-prof-heading {
    font-size: 30px;
    line-height: 38px;
  }
  .events-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .events-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    padding: 10px;
  }
  .catalog-events-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: -15px;
    margin-right: -10px;
    margin-left: -10px;
  }
  .event-page-section {
    margin-top: 15px;
    margin-right: -20px;
    margin-left: -20px;
    padding-bottom: 60px;
  }
  .event-page-section.parent-profile {
    padding-right: 20px;
    padding-left: 20px;
  }
  .event-page-section.create {
    padding-top: 35px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .event-page-header {
    min-height: 200px;
    padding: 20px;
  }
  .event-page-h1 {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 25px;
  }
  .event-page-content {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .event-page-description-row {
    padding-right: 20px;
    padding-left: 20px;
  }
  .event-page-description-column._2 {
    margin-bottom: 50px;
  }
  .event-page-h2 {
    margin-bottom: 30px;
  }
  .event-page-descr-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .event-page-descr-column {
    padding-right: 40px;
  }
  .event-page-h3 {
    margin-bottom: 25px;
  }
  .event-page-map-block {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tasks-tab-link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .task-tab-num {
    margin-right: 0px;
  }
  .task-slide-arrow {
    display: none;
  }
  .courses-row-1 {
    padding-bottom: 20px;
  }
  .event-status-block {
    font-size: 12px;
    line-height: 14px;
  }

  .admin-header-event-page {
    margin-right: -20px;
    margin-bottom: -30px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .edit-event-col {
    width: 100%;
  }
  .edit-event-col.arch {
    width: auto;
  }
  .edit-event-col.news-preview {
    width: auto;
  }
}
//============================450px========================
@media screen and (max-width: 450px) {
  .testing-buttons-div {
    margin: 10px 0 12px;
    margin-bottom: 70px;
  }

  .tab-pane {
    padding-right: 20px;
    padding-left: 20px;
    box-shadow: none;
  }

  .tab-pane.parent-profile {
    display: block;
    padding-top: 25px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  .header-testing {
    margin-top: 56px;
    max-width: 100%;
  }
  .class-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .class-row.class-header-row-2 {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .class-row.class-header-row-3 {
    padding-right: 10px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .class-column._1 {
    min-width: 40px;
    padding-left: 15px;
    font-size: 14px;
  }
  .class-column._3 {
    width: 100%;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .class-column._6 {
    width: 90px;
    margin-right: -15px;
  }
  .class-column._8 {
    width: 100px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .class-column._9 {
    min-width: 40px;
    padding-left: 15px;
    font-size: 14px;
  }
  .class-column._10 {
    padding-right: 0px;
  }
  .class-row-div {
    max-width: 100%;
  }
  .class-row-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .class-row-2.class-header-row-2 {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .tab-link {
    min-width: 90px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .tab-link.add-tab.parent-tabs {
    min-width: 80px;
  }
  .tab-link.parent-tabs {
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .scheme {
    margin-left: -5px;
  }
  .tasks-row {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
  }
  .tasks-column {
    display: block;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .task-block {
    display: block;
  }
  .task-div {
    padding-right: 40px;
    padding-left: 40px;
  }

  .task-text.width-180 {
    max-width: 100%;
  }
  .quote-block {
    display: block;
    text-align: center;
  }
  .quote-photo {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
  }
  .welcome-section {
    padding: 50px 40px;
  }
  .welcome-section.testing {
    padding-top: 50px;
  }

  .welcome-section.teacher-welc {
    padding-bottom: 60px;
  }
  .tab-link-parent {
    display: block;
  }
  .tab-link-parent.dd-add-child {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .tab-photo-child {
    margin-bottom: 3px;
  }
  .test-parent-h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .favorites-heading {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .favorites-column {
    min-width: 240px;
  }
  .class-header._2 {
    padding-right: 15px;
    font-size: 13px;
    font-weight: 400;
  }

  .professions-section {
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: none;
  }

  .professions-section.teacher-welc {
    padding-bottom: 60px;
  }
  .professions-h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .professions-row._2 {
    display: block;
  }
  .professions-wage {
    min-height: 15px;
    padding-top: 1px;
    padding-left: 25px;
    background-size: 15px;
    font-size: 12px;
  }

  .professions-filters {
    display: block;
  }
  .events-row {
    display: block;
  }
  .events-column {
    display: block;
    width: 100%;
  }
  .catalog-events-row {
    display: block;
  }
  .event-page-descr-row {
    display: block;
    margin-bottom: 40px;
  }
  .event-page-descr-column {
    max-width: 100%;
    padding-right: 0px;
  }
  .event-description-list {
    -webkit-column-count: 1;
    column-count: 1;
  }
  .tasks-tab-link {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.5px;
  }
  .task-row {
    display: block;
    margin-right: 0px;
  }
  .task-column {
    display: block;
    width: 100%;
    padding-right: 0px;
  }
  .task-card-heading {
    min-height: 0px;
  }
  .tasks-tab-link-text {
    max-width: 95px;
  }
  .event-gradient-bg-2 {
    width: auto;
    height: 150px;
    max-width: 100%;
  }

  .event-status-text.arch.short {
    position: static;
    margin-top: 15px;
    margin-bottom: 0px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .edit-event-col.arch {
    width: 100%;
    padding-top: 10px;
    padding-left: 30px;
  }
}
