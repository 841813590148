.info-div {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.info-div.child-prof {
  top: -2px;
  right: 0px;
}

.info-div.add-event {
  position: relative;
  top: 7px;
  right: 0px;
  z-index: 101;
}

.info-icon {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-image: url('/assets/images/icons/info-icon.svg');
  background-position: 50% 50%;
  background-size: cover;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: help;
  &.default-cursor {
    cursor: pointer;
  }
}

.info-icon:hover {
  background-image: url('/assets/images/icons/info-icon-hover.svg');
}

.user-data-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  margin-bottom: 0px;
  padding-top: 7px;
  padding-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 16px;
  font-weight: 400;
}

.user-data-field {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 260px;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  padding: 6px 0px 4px;
  border-style: solid;
  border-color: transparent transparent hsla(0, 0%, 59%, 0.1);
  border-bottom-width: 2px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #353535;
  font-size: 16px;
}

.user-data-field:hover {
  border-bottom-color: #5d7aff;
}

.user-data-field:focus {
  border-color: transparent transparent #9375fb;
}

.user-data-field::-webkit-input-placeholder {
  color: #353535;
}

.user-data-field:-ms-input-placeholder {
  color: #353535;
}

.user-data-field::-ms-input-placeholder {
  color: #353535;
}

.user-data-field::placeholder {
  color: #353535;
}

.user-data-field.invite-field {
  width: 350px;
  font-size: 24px;
}

.profile-field-div {
  position: relative;
  width: 260px;
  max-width: 100%;
}

.add-child-text {
  margin-bottom: 35px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.add-child-column {
  padding-right: 70px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.add-child-column._2 {
  position: relative;
  min-width: 350px;
  padding-top: 40px;
  padding-right: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.add-child-code-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.add-child-code-field {
  width: 25px;
  height: auto;
  margin-right: 5px;
  margin-bottom: 0px;
  padding: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent rgba(53, 53, 53, 0.2);
  color: #9375fb;
  font-size: 25px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
}

.add-child-code-field:focus {
  border-style: solid;
  border-color: transparent transparent #9375fb;
}

.add-child-code-field.not-found {
  border-bottom-color: #fd651a;
  color: #fd651a;
}

.add-child-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  margin-top: 15px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.add-child-div.not-found {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.add-child-div-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
}

.add-child-div-2.not-found {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fd651a;
}

.add-child-photo {
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 100px;
}

.add-child-name {
  font-size: 15px;
  line-height: 20px;
}

.add-child-lb.lb {
  display: none;
}

.add-child-lb.lb.show {
  display: block;
}

.add-child-div-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-child-div-3.not-found {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fd651a;
}

.child-or {
  margin-right: 25px;
  margin-left: 25px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.clear-categories {
  display: none;
  margin-right: 10px;
  margin-left: 10px;
}

.clear-categories.show {
  display: block;
}

.clear-categories.events-clear {
  margin-right: 0px;
  margin-bottom: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.search-categories {
  height: auto;
  margin-bottom: 0px;
  padding: 5px 0px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent rgba(53, 53, 53, 0.1);
  color: #353535;
  font-size: 15px;
}

.search-categories:focus {
  border-color: transparent transparent #5d7aff;
  border-bottom-style: solid;
}

.search-categories::-webkit-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.search-categories:-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.search-categories::-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.search-categories::placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.form-block {
  margin-bottom: 0px;
}

.search-clear {
  position: absolute;
  top: 50%;
  right: 0px;
  display: none;
  width: 20px;
  height: 20px;
  background-image: url('/assets/images/icons/delete.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.2;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.search-clear:hover {
  opacity: 1;
}

.search-clear.show {
  display: inline-block;
}

.search-block {
  position: relative;
}
.form-block-2 {
  margin-bottom: 0px;
}

.add-achieve-field {
  max-width: 350px;
  min-height: 45px;
  min-width: 100%;
  margin-bottom: 0px;
  padding: 0px 0px 4px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent rgba(53, 53, 53, 0.1);
  background-color: transparent;
  color: #353535;
  font-size: 16px;
  line-height: 20px;
}

.add-achieve-field:focus {
  border-style: solid;
  border-width: 2px;
  border-color: transparent transparent #9375fb;
}

.add-achieve-field::-webkit-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.add-achieve-field:-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.add-achieve-field::-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.add-achieve-field::placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.add-achieve-field.error {
  border-bottom-color: #fd651a;
}

.create-event-form {
  margin-bottom: 0px;
}

.create-form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  padding-bottom: 45px;
}

.create-form-label {
  margin-top: 5px;
  margin-right: 15px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
}

.create-form-column {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.create-form-column._1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  padding-right: 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.create-form-column.flex-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.create-event-field {
  height: 30px;
  margin-bottom: 0px;
  padding: 4px 0px;
  border-style: none none solid;
  border-width: 2px;
  border-color: transparent transparent rgba(53, 53, 53, 0.1);
  background-color: transparent;
  color: #353535;
  font-size: 15px;
  line-height: 20px;
}

.create-event-field:focus {
  border-style: none none solid;
  border-width: 2px;
  border-color: transparent transparent #9375fb;
}

.create-event-field::-webkit-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.create-event-field:-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.create-event-field::-ms-input-placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.create-event-field::placeholder {
  color: rgba(53, 53, 53, 0.2);
}

.create-event-field.error {
  border-bottom-color: #fd651a;
}

.create-event-field.date {
  width: 160px;
  margin-right: 50px;
  background-image: url('/assets/images/dashboard/calendar.svg');
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.create-event-field.reportDate {
  width: 300px;
  margin-right: 50px;
  background-image: url('/assets/images/dashboard/calendar.svg');
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.create-event-field.time {
  width: 40px;
}

.create-event-field.cost {
  width: 90px;
}

.create-event-field.error-field {
  border-bottom-color: #ff5722;
}

.create-event-field.error-field::-webkit-input-placeholder {
  color: #ff5722;
}

.create-event-field.error-field:-ms-input-placeholder {
  color: #ff5722;
}

.create-event-field.error-field::-ms-input-placeholder {
  color: #ff5722;
}

.create-event-field.error-field::placeholder {
  color: #ff5722;
}

.under-field-text {
  margin-top: 10px;
  color: rgba(53, 53, 53, 0.3);
  font-size: 13px;
  line-height: 15px;
}

.create-event-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.edit-event-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.edit-event-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.edit-event-column._2 {
  padding-top: 18px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: right;
}

.edit-event-column.event-page-edit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.edit-event-button-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.edit-event-buttons-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.cost-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

//====================================
//===============MEDIA================
//====================================
//============================991px========================
@media screen and (max-width: 991px) {
  .info-div.add-event {
    top: 4px;
  }

  .add-child-column._2 {
    padding-top: 0px;
    padding-bottom: 15px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .clear-categories {
    margin-right: 5px;
  }
  .add-achieve-field {
    max-width: 250px;
  }
  .achievement-link {
    display: none;
  }
  .create-form-row {
    display: block;
    padding-bottom: 35px;
  }
  .create-form-row._2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .create-form-row._2.prof {
    padding-right: 90px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .create-form-column._1 {
    width: auto;
    padding-bottom: 15px;
  }
  .edit-event-row {
    display: block;
  }
  .edit-event-column._2 {
    margin-left: -30px;
    text-align: left;
  }
  .edit-event-column.event-page-edit {
    margin-left: -30px;
    padding-top: 5px;
  }
}
//============================768px========================
@media screen and (max-width: 768px) {
  .user-data-label {
    width: auto;
    padding-top: 0px;
    padding-right: 0px;
  }
  .user-data-field.invite-field {
    font-size: 14px;
  }
  .add-child-text {
    margin-bottom: 40px;
  }
  .add-child-column {
    max-width: 400px;
    padding-right: 0px;
  }
  .add-child-column._2 {
    min-width: 0px;
  }
  .create-form-row._2.prof-2 {
    display: block;
  }
  .create-event-row._2 {
    padding-bottom: 10px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .edit-event-column.event-page-edit {
    padding-top: 0px;
  }
}
//============================450px========================
@media screen and (max-width: 450px) {
  .add-child-div {
    display: block;
  }
  .add-child-div.not-found {
    display: block;
  }
  .add-child-div.found {
    display: block;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .add-child-div-2 {
    width: auto;
    margin-top: 25px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .add-achieve-field {
    max-width: 100%;
  }
  .create-form-row._2 {
    display: block;
  }
  .add-child-div-3 {
    width: auto;
    margin-top: 25px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
