@import '../styles/global-vars/colors.component.scss';
@import '../styles/global-vars/variables&mixins.component.scss';
@import '../styles/global-vars/fonts.scss';

.font-Avenir-Next {
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
}

.base-input {
  background: #ffffff;
  border: 2px solid $color-purple;
  box-sizing: border-box;
  border-radius: 5px;
  // color: $color-purple;
}
