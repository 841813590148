$color-white: #fff;
$color-light-purple: #9375fb;
$color-purple: #7852fb;
$color-lightpurple: #8d60ec;
$color-black: #000;
$color-black1: #333333;
$color-black2: #353535;
$color-orange: #ff5722;
$color-grassgreen: #36ca75;
$color-pastel: #13e5db;
$color-pink: #e74daa;
$color-blue: #42a2ff;

$purple_new: #8e75f3;
$green_new: #67c77d;
$grass_green: #19d481;
$gray_common: #c6c6c6;
$gray_dark: #c4c4c4;
$blue_common: #607bf7;
$blue_dark: #3d5adf;
$blue_text: #3e60fd;

$disabled_grey: '';
$disabled_blue: #e7ebfe;

//=================
$indigo: #5867dd;
$purple: #716aca;
$main-color-a: rgb(237, 8, 119);
$main-color-b: rgb(0, 114, 188);
$main-color-c: rgb(0, 181, 208);
$main-color-d: rgb(198, 205, 35);
$main-color-e: rgb(0, 0, 0);
$default-font-size: 10;
$button-background-b: #0db5fc;
$button-color-b: #fff;
$success: #34bfa3;
$greenio: #81e6a0;
