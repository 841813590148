.test-result-h1 {
  margin-bottom: 20px;
  font-size: 35px;
  line-height: 40px;
  font-weight: 500;
}

.test-result-h1._2 {
  margin-right: 170px;
  margin-left: 170px;
}

.test-result-h1.rec {
  margin-top: 105px;
  margin-bottom: 25px;
}

.test-result-h2 {
  margin: 0 14px;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  color: $color-black2;
}

.test-result-text {
  display: inline-block;
  max-width: 500px;
  margin-bottom: 40px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.test-result-text._w-680 {
  max-width: 680px;
}

.test-result-text._w-700 {
  display: block;
  max-width: 100%;
}

.read-more-2 {
  display: inline-block;
  color: #9375fb;
  font-size: 13px;
}
.test-result-block {
  margin-bottom: 115px;
}

.test-result-diagram {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.test-result-diagram._2 {
  display: block;
}

.test-result-parameters-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.test-result-parameters-text {
  width: 205px;
  margin-right: 25px;
  font-size: 15px;
}

.test-result-parameters-text.problem {
  padding-right: 23px;
  background-image: url('/assets/images/icons/problem-icon.svg');
  background-position: 100% 50%;
  background-repeat: no-repeat;
  color: #ff5722;
  font-weight: 700;
}

.test-result-parameters-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.test-result-parameters-num {
  width: 36px;
  margin-left: 25px;
  border-radius: 7px;
  background-color: #36ca75;
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
}

.test-result-parameters-num.parent {
  margin-left: 5px;
  background-color: #3e60fd;
}

.test-result-parameters-line {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background-color: #36ca75;
}

.test-result-parameters-line._2 {
  width: 35%;
}

.test-result-parameters-line._3 {
  width: 25%;
}

.test-result-parameters-line._4 {
  width: 76%;
}

.test-result-parameters-line._5 {
  width: 63%;
}

.test-result-parameters-line._6 {
  width: 45%;
}

.test-result-parameters-bar {
  position: relative;
  height: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.07);
}

.test-result-parameters-oval {
  width: 16px;
  height: 16px;
  margin-top: -3px;
  float: right;
  border-radius: 100px;
  background-color: #36ca75;
  -webkit-transform: translate(50%, 0%);
  -ms-transform: translate(50%, 0%);
  transform: translate(50%, 0%);
}

.test-result-parameters-oval.parent {
  position: absolute;
  top: 0px;
  margin-left: 35%;
  float: none;
  background-color: #3e60fd;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.test-result-parameters-oval.parent._2 {
  margin-left: 67%;
}

.test-result-parameters-oval.parent._3 {
  margin-left: 31%;
}

.test-result-parameters-oval.parent._4 {
  margin-left: 67%;
}

.test-result-parameters-oval.parent._5 {
  margin-left: 14%;
}

.test-result-parameters-oval.parent._6 {
  margin-left: 48%;
}

.test-result-description-image {
  margin-top: 15px;
  margin-bottom: 30px;
}

.test-result-description-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -80px;
  margin-bottom: -100px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
}

.test-result-description-column {
  width: 50%;
  padding-right: 80px;
  padding-bottom: 25px;
}

.test-result-description-heading {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 25px;
}

.test-result-description-text {
  color: #353535;
  font-size: 15px;
}

.after-testing-text {
  display: inline-block;
  max-width: 440px;
  margin-bottom: 45px;
  color: #353535;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}

.test-result-info-div {
  position: relative;
  margin-top: 10px;
}

.test-result-info-div:hover {
  z-index: 2;
}
.diagram-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.diagram-row._2 {
  margin-top: 40px;
  margin-left: 230px;
}

.diagram-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
}

.diagram-color-oval {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50px;
  background-color: #36ca75;
}

.diagram-color-oval.blue {
  background-color: #3e60fd;
}

.diagram-column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.test-result-image {
  margin-bottom: 40px;
}

.diagram-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 300px;
  margin-top: -65px;
  margin-left: 40px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
  text-align: left;
}

.diagram-info-icon {
  margin-right: 20px;
  margin-bottom: 0px;
}

.diagram-info-paragraph {
  margin-top: 7px;
  margin-bottom: 0px;
}

.container-test-results {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .results-container {
    flex: 1 1 auto;
  }
}

.diagnostic-passed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 72px auto;
  max-width: 550px;

  .celebration-image {
    img {
      width: 100px;
      height: 50px;
    }
  }

  .diagnostic-passed-text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .diagnostic-passed-title {
    font-weight: 800;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }

  .diagnostic-passed-desc {
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;
  }
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .test-result-diagram {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .test-result-description-row {
    margin-right: -60px;
  }
  .test-result-description-column {
    padding-right: 60px;
  }
  .diagram-info {
    max-width: 500px;
    margin-top: 40px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 768px) {
  .test-result-h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .test-result-h1.rec {
    margin-top: 85px;
  }
  .test-result-block {
    margin-bottom: 90px;
  }
  .test-result-diagram {
    margin-top: 0px;
  }
  .test-result-parameters-row {
    display: block;
  }
  .test-result-parameters-text {
    display: inline-block;
    width: auto;
    margin-right: 0px;
    margin-bottom: 5px;
  }
  .test-result-parameters-text.problem {
    padding-right: 25px;
  }
  .test-result-description-row {
    display: block;
    margin-right: 0px;
  }
  .test-result-description-column {
    width: 100%;
    padding-right: 0px;
  }
  .test-result-info-div {
    display: block;
  }
  .diagram-row._2 {
    margin-left: 0px;
  }
  .diagram-info {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 450px) {
  .diagram-row {
    display: block;
    margin-right: 0px;
  }
  .diagram-column {
    padding-right: 0px;
  }
}
