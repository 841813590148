//----------lb---------

.confirm-popup {
  display: block;
}
.popup-block {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  width: 664px;
  max-width: 80%;
  margin-top: 15vh;
  border-radius: 20px;
  background-color: #fff;
  padding: 55px 60px 49px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
  transform: translate(-50%);
}
.popup-column {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}

.lb {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 3000;
  display: none;
  overflow: auto;
}

.lb-bg {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: hsla(45, 40%, 98%, 0.95);
}

.lb-block {
  position: relative;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 900px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lb-block.prof-popup {
  padding: 80px 50px 60px;
}

.lb-block.task-lb {
  top: 0%;
  width: 1000px;
  max-width: 90%;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.lb-block.sources-lb {
  top: 0%;
  width: 1172px;
  max-width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 100px;
  padding-bottom: 55px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.lb-block.goal {
  padding-top: 40px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.lb-block.add-child-lb-block {
  padding-left: 60px;
}
.close-lb {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.perform-task {
  display: inline-block;
  margin-top: 10px;
}

.perform-task-lb.lb {
  display: none;
}

.perform-task-lb.lb.show {
  display: block;
}

.tasl-lb-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.task-lb-column {
  padding: 45px 60px 55px 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 15px;
}

.task-lb-column._2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  max-width: 32vw;
  padding-top: 55px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: rgba(53, 53, 53, 0.02);
}

.task-lb-heading {
  margin-bottom: 40px;
  font-size: 35px;
  line-height: 40px;
}

.task-description p {
  margin-bottom: 1.5em;
}

.task-description h4 {
  margin-top: 2em;
  margin-bottom: 20px;
  color: #353535;
  font-size: 15px;
}

.task-lb-heading-result {
  margin-bottom: 20px;
  color: #353535;
  font-size: 25px;
  line-height: 30px;
}

.add-achievement-lb.lb {
  display: none;
}

.add-achievement-lb.lb.show {
  display: block;
}

.lb-block-achievement {
  position: relative;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 900px;
  padding: 55px 50px 50px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lb-block-achievement.prof-popup {
  padding-top: 80px;
  padding-right: 100px;
  padding-bottom: 60px;
}

.lb-block-achievement.task-lb {
  top: 0%;
  width: 1000px;
  max-width: 90%;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.lb-block-achievement.add-article {
  width: 650px;
}

.lb-block-achievement.add-fact {
  width: 820px;
}

.achievement-card-lb {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 210px;
  height: 280px;
  padding: 60px 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 10px;
  background-color: #ff8a64;
  color: #fff;
  text-align: center;
}

.achievement-card-lb.purple {
  background-color: #9375fb;
}

.achievement-card-lb.blue {
  background-color: #5d7aff;
}

.achievement-card-lb.light-green {
  background-color: #00d5cb;
}

.achievement-card-lb.yellow {
  background-color: #fdcf00;
}

.achievement-card-lb.pink {
  background-color: #ff7aed;
}

.achievement-card-lb.green {
  background-color: #36ca75;
}

.achievement-card-lb.dark-yellow {
  background-color: #ffad00;
}

.achievement-card-lb.brown {
  background-color: #ad896a;
}

.add-achievement-lb-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 2px solid rgba(26, 30, 38, 0.05);
}

.add-achievement-text {
  margin-bottom: 40px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.add-achievement-column {
  position: relative;
  z-index: 1;
  max-width: 460px;
  margin-right: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 460px;
  -ms-flex: 0 460px;
  flex: 0 460px;
}

.add-achievement-column.add-prof {
  max-width: 550px;
  padding-top: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.add-achievement-column.add-article {
  max-width: 100%;
  padding-top: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.add-achievement-row {
  position: relative;
  margin-bottom: 35px;
  padding-left: 110px;
}

.add-achievement-label {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-bottom: 0px;
  padding-right: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  font-size: 16px;
}

.add-achievement-label._2 {
  top: 0%;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.add-achieve-column {
  max-width: 100%;
}

.h3-add-achievement {
  margin-bottom: 15px;
  padding-right: 15px;
  font-size: 36px;
  line-height: 45px;
}

.attached-file-row-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #9375fb;
}

.attached-file-row-2._2 {
  margin-bottom: 20px;
}

.attach-icon-link {
  line-height: 1em;
}

.achievement-link {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 10px;
}

.achievement-mobile-link {
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
}

.attached-file-column {
  width: 35px;
  margin-top: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: rgba(53, 53, 53, 0.5);
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.3px;
}

.attached-file-title {
  overflow: hidden;
  max-width: 100%;
  margin-top: 11px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.attached-files-list {
  margin-bottom: 20px;
}

.complete-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.complete-icon {
  margin-bottom: 15px;
}

.complete-text {
  color: #00d5cb;
  font-size: 14px;
  line-height: 18px;
}

.info-popup {
  position: absolute;
  z-index: 1000;
  right: 50%;
  bottom: 100%;
  width: 245px;
  margin-bottom: 10px;
  padding: 15px 15px 5px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(50%, 0px);
  -ms-transform: translate(50%, 0px);
  transform: translate(50%, 0px);
  font-weight: 500;
  text-align: left;
}
.info-popup.opened {
  display: block !important;
}

.info-popup.stud-code {
  right: -15px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.info-popup.test-date {
  right: -15px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  &.disabled {
    display: none !important;
  }
}

.info-popup.stud-prof {
  display: inline-block;
  width: 190px;
  padding-right: 20px;
  text-align: center;
}

.info-popup.prof-popup {
  padding-left: 15px;
  text-align: center;
}

.info-popup.prof-popup.right {
  right: -15px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.info-popup.recommendation {
  z-index: 1;
  width: 210px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14px;
  text-align: center;
}

.info-popup.attach {
  top: 100%;
  right: 50%;
  bottom: auto;
  z-index: 1;
  width: 330px;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 30px 30px 5px;
}

.info-popup.company-name {
  width: 170px;
  padding-left: 15px;
  line-height: 18px;
  text-align: center;
}

.info-popup.achievement-mobile-popup {
  bottom: 10%;
  width: auto;
}

.info-popup.add-event.right {
  right: -15px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.info-triangle {
  position: absolute;
  left: 50%;
  bottom: 0px;
  -webkit-transform: translate(-50%, 100%);
  -ms-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

.info-triangle.stud-code {
  left: auto;
  right: 13px;
  bottom: 0px;
}
.info-triangle.prof-arrow {
  bottom: 0px;
}

.info-triangle.prof-arrow.right {
  left: auto;
  right: 13px;
  bottom: 0px;
}

.overlay-cap {
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: 525px;
  right: -60px;
  bottom: 0;
  left: -60px;
  z-index: 10;
  background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 0.83) 31.77%, #f8f8f8 59.9%);
  .overlay-cap-content {
    margin-top: auto;
    position: relative;
    width: 100%;
    padding: 0 60px 50px;
  }

  .cap-data-container {
    background: #eef1fe;
    border-radius: 17px;
    padding: 32px 68px;
  }
  .cap-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: $color-black2;
  }
  .cap-text {
    margin-top: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $color-black2;
  }
}

.popup-overlay-new {
  background: rgba(196, 196, 196, 0.2);
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .popup-container {
    position: relative;
    padding: 100px 45px 62px;
    display: block;
    margin: 15vh auto 0;
    max-width: 560px;
    width: 90%;
    background: $color-white;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    .popup-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 26px;
      height: 26px;
      border-radius: 50%;

      cursor: pointer;
    }
    .popup-title {
      margin: 0 auto;
      @extend .font-Avenir-Next;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      color: #353535;
    }
    .popup-content {
      margin-top: 13px;
      position: relative;
      @include flex();
      @include justify-content(space-between);

      .content-text {
        position: relative;
        flex: 1;
        .info-text {
          display: flex;
          margin-top: 32px;
        }

        .popup-text {
          max-width: 352px;
          margin: 0 auto;
          max-width: 400px;
          font-weight: 500;
          @extend .font-Avenir-Next;
          text-align: center;

          .text-link {
            color: #9375fb;
          }
        }
        .popup-number {
          margin-top: 60px;
          .popup-input {
            padding: 8px 12px;
            width: 100%;
            background: transparent;
            outline: none;
            border: none;
            font-weight: 600;
            font-size: 40px;
            line-height: 55px;
            text-align: center;
            color: #353535;
            &.error-field {
              border-bottom: 2px solid transparent;
              border-bottom-color: #ff5722;
            }
          }
        }
      }

      .popup-buttons {
        margin-top: 79px;
        display: flex;
        justify-content: center;
        gap: 14px;
        .popup-button {
          min-height: 48px;
          flex: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          @extend .font-Avenir-Next;
          font-weight: 800;
          font-size: 16px;
          line-height: 24px;
          box-shadow: none;

          &._white {
            color: #353535;
            background: #fff;
            border: 1px solid #c8d1fc;
            border-radius: 12px;
            color: #607bf7;
          }
          &.disabled {
            border: transparent;
            pointer-events: none;
            background: #e7ebfe;
            color: #fff;
          }
        }
      }
    }
  }
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .lb-block {
    left: 0%;
    top: 0%;
    overflow: hidden;
    width: auto;
    margin: 30px 30px 60px;
    padding-left: 60px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .lb-block.prof-popup {
    padding-right: 60px;
  }
  .lb-block.task-lb {
    left: 50%;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 1px;
  }
  .lb-block.sources-lb {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .lb-block.sources-lb.mob {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    overflow: auto;
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
  }
  .lb-block.goal {
    left: 50%;
    top: 50%;
    width: 600px;
    max-width: 90%;
    margin: 0px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .lb-block.add-child-lb-block {
    padding-left: 50px;
  }
  .close-lb.mob {
    position: fixed;
  }
  .tasl-lb-row {
    display: block;
  }
  .task-lb-column._2 {
    width: 100%;
    max-width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .attached-file-column {
    width: 45px;
  }
  .attached-files-list {
    width: 400px;
    max-width: 100%;
  }

  .lb-block-achievement {
    left: 0%;
    top: 0%;
    width: auto;
    margin: 30px 30px 60px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .lb-block-achievement.prof-popup {
    padding-right: 60px;
  }
  .lb-block-achievement.task-lb {
    left: 50%;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 1px;
  }
  .lb-block-achievement.add-article {
    width: auto;
  }
  .lb-block-achievement.add-fact {
    width: auto;
  }
  .add-achievement-lb-row {
    position: relative;
  }
  .add-achievement-column {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .info-popup.prof-popup {
    right: -15px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .info-triangle.prof-arrow {
    left: auto;
    right: 11px;
    bottom: 0px;
  }
}
//=========================768px====================
@media screen and (max-width: 768px) {
  .lb-block {
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
  }
  .lb-block.prof-popup {
    margin-bottom: 40px;
    padding-top: 60px;
    padding-right: 40px;
  }
  .lb-block.task-lb {
    margin-top: 30px;
  }
  .lb-block.sources-lb {
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left: 30px;
  }
  .lb-block.goal {
    top: 0%;
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 30px;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }
  .lb-block.add-child-lb-block {
    padding-left: 40px;
  }
  .task-lb-column {
    padding-right: 30px;
    padding-bottom: 45px;
    padding-left: 30px;
  }
  .task-lb-column._2 {
    padding-top: 35px;
    padding-left: 30px;
  }
  .task-lb-heading {
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 36px;
  }
  .task-description {
    font-size: 14px;
  }
  .attached-file-column {
    margin-top: 9px;
    line-height: 22px;
  }
  .attached-file-title {
    line-height: 20px;
  }
  .complete-message {
    padding-bottom: 50px;
  }
  .lb-block-achievement {
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 40px;
    padding-bottom: 50px;
    padding-left: 40px;
  }
  .lb-block-achievement.prof-popup {
    margin-bottom: 40px;
    padding-top: 60px;
    padding-right: 40px;
  }
  .lb-block-achievement.task-lb {
    margin-top: 30px;
  }
  .achievement-card-lb {
    display: none;
  }
  .add-achievement-lb-row {
    display: block;
  }
  .add-achievement-text {
    margin-bottom: 40px;
  }
  .add-achievement-column {
    margin-right: 0px;
    padding-right: 0px;
  }
  .add-achievement-row {
    margin-bottom: 30px;
  }
  .h3-add-achievement {
    font-size: 28px;
    line-height: 35px;
  }
  .attached-file-row-2 {
    font-size: 12px;
    line-height: 15px;
  }
  .attached-file-row-2._2 {
    margin-bottom: 15px;
  }
  .info-popup.recommendation {
    display: none;
  }
  .info-popup.attach {
    max-width: 45vw;
    padding-top: 20px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .info-popup.company-name {
    width: 140px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
  }

  .popup-overlay-new {
    background: rgba(53, 53, 53, 0.2);
    .popup-container {
      padding: 50px 24px 44px;
      margin: 30vh auto 0;
      border-radius: 17px;
      .popup-close {
        top: 20px;
        right: 20px;
      }
      .popup-title {
        margin: 0 auto;
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
      }
      .popup-content {
        margin-top: 14px;
        .content-text {
          .info-text {
            display: flex;
            margin-top: 36px;
          }
          .popup-text {
            font-size: 16px;
            line-height: 20px;
          }
          .popup-number {
            margin-top: 32px;
            .popup-input {
              font-size: 28px;
              line-height: 36px;
            }
          }
        }

        .popup-buttons {
          margin-top: 40px;
          gap: 14px;
          .popup-button {
            padding: 8px 20px;
            max-width: 300px;
            flex: 50%;
          }
        }
      }
    }
  }
}
//==========================450px===================
@media screen and (max-width: 450px) {
  .lb-block {
    padding-right: 30px;
    padding-left: 30px;
  }
  .lb-block.prof-popup {
    padding-right: 30px;
  }
  .lb-block.goal {
    left: 0%;
    width: auto;
    max-width: 100%;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .lb-block.add-child-lb-block {
    padding-left: 30px;
  }
  .task-lb-heading {
    font-size: 22px;
    line-height: 30px;
  }
  .lb-block-achievement {
    padding-right: 25px;
    padding-left: 25px;
  }
  .lb-block-achievement.prof-popup {
    padding-right: 30px;
  }
  .achievement-card-lb {
    height: 220px;
    padding: 40px 20px;
  }
  .add-achieve-column {
    max-width: 100%;
  }
  .info-popup {
    width: 240px;
  }
  .info-popup.stud-code {
    right: -15px;
    -webkit-transform: translate(0%, 0px);
    -ms-transform: translate(0%, 0px);
    transform: translate(0%, 0px);
  }
  .info-popup.prof-popup {
    right: -15px;
    -webkit-transform: translate(0%, 0px);
    -ms-transform: translate(0%, 0px);
    transform: translate(0%, 0px);
  }
  .info-popup.stud-code-2 {
    -webkit-transform: translate(63%, 0px);
    -ms-transform: translate(63%, 0px);
    transform: translate(63%, 0px);
  }
  .info-popup.attach {
    max-width: 90vw;
  }
  .info-popup.company-name {
    width: 120px;
  }
  .info-popup.add-event {
    right: -15px;
    -webkit-transform: translate(0%, 0px);
    -ms-transform: translate(0%, 0px);
    transform: translate(0%, 0px);
  }
  .info-triangle.stud-code {
    right: 13px;
  }
  .info-triangle.stud-code-2 {
    left: 37%;
  }
  .info-triangle.prof-arrow {
    right: 13px;
  }
  .info-triangle.add-event {
    left: auto;
    right: 20px;
    bottom: 0px;
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
  }

  .overlay-cap {
    .overlay-cap-content {
    }

    .cap-data-container {
      padding: 32px 22px 26px 44px;
    }
    .cap-title {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
    }
    .cap-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .popup-overlay-new {
    background: rgba(53, 53, 53, 0.2);
    .popup-container {
      padding: 36px 14px 44px;
      margin: 30vh auto 0;
      max-width: 320px;
      border-radius: 17px;
      .popup-close {
        top: 20px;
        right: 20px;
      }
      .popup-title {
        margin: 0 auto;
        font-weight: 800;
        font-size: 22px;
        line-height: 30px;
      }
      .popup-content {
        margin-top: 12px;
        position: relative;
        @include flex();
        @include justify-content(space-between);

        .content-text {
          position: relative;
          padding-bottom: 0;
          flex: 1;

          .info-text {
            display: flex;
            margin-top: 32px;
          }

          .popup-text {
            max-width: unset;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            color: rgba(53, 54, 54, 0.5);
            .text-link {
              color: #9375fb;
            }
          }
          .popup-number {
            margin-top: 29px;
            .popup-input {
              font-size: 26px;
              line-height: 36px;
              text-align: center;
              color: #353635;
            }
          }
        }

        .popup-buttons {
          margin-top: 35px;
          flex-direction: column;
          gap: 12px;
          .popup-button {
            max-width: unset;
            flex: unset;
          }
        }
      }
    }
  }
}
